<template>
  <div class="container">
    <BaseH1 :text="'Opowiedź o Siebie'" />

    <div class="wrapper container__wrapper">
      <InputTextTextareaAboutMe
        v-model="application.aboutMe.value"
        @update:is-valid-about-me="application.aboutMe.isValid = $event"
      />
      <TextHint :text="'Zawsze możesz edytować pózniej :)'" />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputTextTextareaAboutMe from "@/components/UI/InputTextTextareaAboutMe.vue";
import TextHint from "@/components/UI/TextHint.vue";

export default {
  components: {
    BaseH1,
    TextHint,
    InputTextTextareaAboutMe,
  },

  props: {
    aboutMe: String,
  },

  emits: ["update:aboutMe", "update:isValid"],

  setup(props, { emit }) {
    const application = reactive({
      aboutMe: {
        value: props.aboutMe,
        isValid: false,
      },
    });

    watch(
      () => application.aboutMe.value,
      (newAboutMe) => {
        emit("update:aboutMe", newAboutMe);
      }
    );

    watch(
      () => props.aboutMe,
      (newAboutMe) => {
        application.aboutMe.value = newAboutMe;
      }
    );

    const isValid = computed(() => {
      return application.aboutMe.isValid;
    });

    watch(
      isValid,
      (newIsValid) => {
        emit("update:isValid", newIsValid);
      },
      { immediate: true }
    );

    return {
      application,
      isValid,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.container__wrapper {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}

@media (max-width: 1200px) {
}
</style>
