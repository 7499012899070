<template>
  <div class="content">
    <ApplicationNannyInputAboutMe
      v-model:about-me="applicationNanny.details.description"
      @update:is-valid="isValid = $event"
    />

    <ButtonPrimaryNext
      @click="handleCreateApplication()"
      :isDisabled="!isValid"
    />
  </div>
</template>

<script>
import { useApplicationNannyStore } from "@/stores/applicationNanny";
import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";
import { useUserStore } from "@/stores/user";

import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";
import ApplicationNannyInputAboutMe from "@/components/Application/Nanny/ApplicationNannyInputAboutMe.vue";

export default {
  components: {
    ApplicationNannyInputAboutMe,
    ButtonPrimaryNext,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();
    const userStore = useUserStore();

    const isValid = ref(null);

    const router = useRouter();
    const handleCreateApplication = async () => {
      try {
        if (applicationNanny.meta.status === "published") {
          await applicationNanny.updateApplication();
          router.push({ name: "ApplicationNannyCreatePhotoMain" });
          return;
        }

        applicationNanny.meta.status = "published";
        await applicationNanny.createApplication();
        await userStore.refetchUserStore();
        router.push({ name: "ApplicationNannyCreatePhotoMain" });
      } catch (error) {
        applicationNanny.meta.status = "draft";
        console.log(error);
      }
    };

    return {
      applicationNanny,
      isValid,
      handleCreateApplication,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}

@media (max-width: 1200px) {
}
</style>
