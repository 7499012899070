<template>
  <InputTextTextarea
    :name="'about-me'"
    :rows="10"
    :maxlength="300"
    :model-value="modelValue"
    @update:model-value="
      $emit('update:modelValue', $event);
      aboutMe = $event;
    "
    @focus="isErrorAboutMe = false"
    @blur="validateAboutMe"
    :error="isErrorAboutMe"
    class="text-input-custom about-me"
  />
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

export default {
  props: {
    modelValue: String,
  },

  emits: ["update:modelValue", "update:isValidAboutMe"],

  setup(props, { emit }) {
    const aboutMe = ref(props.modelValue);

    // Validation on emptyness
    const isErrorAboutMe = ref(false);
    const validateAboutMe = () => {
      isErrorAboutMe.value = !aboutMe.value.length;
    };

    const isValidAboutMe = computed(() => {
      return !!aboutMe.value.length;
    });

    watch(
      isValidAboutMe,
      (newIsValidAboutMe) => {
        emit("update:isValidAboutMe", newIsValidAboutMe);
      },
      { immediate: true }
    );

    return {
      aboutMe,
      isErrorAboutMe,
      validateAboutMe,
      isValidAboutMe,
    };
  },
};
</script>

<style scoped>
.text-input-custom.about-me {
  width: 560px;
}
@media (max-width: 1200px) {
  .text-input-custom.about-me {
    width: 100%;
  }
}
</style>
